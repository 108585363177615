import Box from "@mui/material/Box";
import TimeInput from "../components/TimeInput/TimeInput";
import Dropdown from "../components/Dropdown/Dropdown";
import actionTypes from "../data/actionTypes.json";
import Notes from "../components/Notes/Notes";
import SubmitButton from "../components/SubmitButton/SubmitButton";
import React, {useEffect, useState} from "react";
import useFetch from "../hooks/useFetch";
import {fetchCustomers, fetchJobs, fetchLineManagers} from "../services/api";
import Container from "@mui/material/Container";
import ActiveLastBreadcrumb from "../components/BredCrumbs/BreadCrumbs";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {Grid} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


const Home = () => {
    const [selectedTimeSpent, setSelectedTimeSpent] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const {data: customers, loading: customersLoading} = useFetch(fetchCustomers);
    const [selectedJob, setSelectedJob] = useState('');
    const [jobsData, setJobsData] = useState([]);
    const [selectedJobId, setSelectedJobId] = useState('');
    const [lineManagers, setLineManagers] = useState([]);
    const [lineManagersLoading, setLineManagersLoading] = useState(false);
    const [selectedLineManager, setSelectedLineManager] = useState('');
    const [selectedAction, setSelectedAction] = useState('');
    const [notes, setNotes] = useState('');
    const [selectedTime, setSelectedTime] = useState(null);

    const accessToken = localStorage.getItem('accessToken');

    const resetDropdowns = () => {
        setSelectedTimeSpent('');
        setSelectedCustomer(null);
        setSelectedJob('');
        setSelectedAction('');
        setSelectedLineManager('');
        setNotes('');
    };

    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
    };

    const handleJobSelect = (selectedJob) => {
        setSelectedJob(selectedJob);
        const selectedJobData = jobsData.find(job => job.jobId === selectedJob.jobId);
        if (selectedJobData) {
            setSelectedJobId(selectedJobData.jobid);
        }
    };

    const updateSelectedJobId = (newJobId) => {
        setSelectedJobId(newJobId);
    };

    const lineManagerOptions =
        lineManagers.map(manager => ({
                label: `${manager.firstName} ${manager.lastName}`,
                value: `${manager.email}`,
            }
        ));

    useEffect(() => {
        if (selectedCustomer && selectedCustomer.customer_name) {
            fetchJobs(selectedCustomer.customer_name).then(response => {
                setJobsData(response);
            });
        }
    }, [selectedCustomer]);

    useEffect(() => {
        setLineManagersLoading(true);
        fetchLineManagers(accessToken)
            .then(data => {
                setLineManagers(data);
                setLineManagersLoading(false);
            })
            .catch(error => {
                console.error('Failed to fetch line managers:', error);
                setLineManagersLoading(false);
            });
    }, [accessToken])

    return (
        <div style={{margin: "0px 20px 20px 20px"}}>
            <Container>
            <ActiveLastBreadcrumb/>
            {/*<Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} style={{width: "100%", margin: "0"}}>*/}
            {/*    <Grid style={{paddingRight: "5px"}} xs={6}>*/}
                    <TimeInput
                        time={selectedTimeSpent}
                        setTime={setSelectedTimeSpent}
                        label="Time Spent"
                    />
                {/*</Grid>*/}
                {/*<Grid style={{paddingLeft: "5px"}} xs={6}>*/}
                    <Dropdown
                        label="Customers"
                        options={customers}
                        selected={selectedCustomer}
                        onChange={setSelectedCustomer}
                        loading={customersLoading}
                        valueKey="customer_name"
                    />
                {/*</Grid>*/}
                {/*<Grid style={{paddingRight: "5px"}} xs={6}>*/}
                    <Dropdown
                        label="Jobs"
                        options={jobsData}
                        selected={selectedJob}
                        onChange={handleJobSelect}
                        // loading={jobsLoading}
                        valueKey="job_desc"
                    />
                {/*</Grid>*/}
                {/*<Grid style={{paddingLeft: "5px"}} xs={6}>*/}
                    <Dropdown
                        label="Action Type"
                        options={actionTypes}
                        selected={selectedAction}
                        onChange={setSelectedAction}
                    />
                {/*</Grid>*/}
                {/*<Grid style={{paddingRight: "5px"}} xs={6}>*/}
                    <Dropdown
                        label="Line Manager"
                        options={lineManagerOptions}
                        selected={selectedLineManager}
                        onChange={(newValue) => setSelectedLineManager(newValue)}
                        isLoading={lineManagersLoading}
                        valueKey="value"
                    />
                {/*</Grid>*/}
                {/*<Grid style={{paddingLeft: "5px"}} xs={6}>*/}
                {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                {/*    <DemoContainer components={['TimePicker']}>*/}
                {/*        <TimePicker*/}
                {/*            label="Basic time picker"*/}
                {/*            value={selectedTime}*/}
                {/*            onChange={handleTimeChange}*/}
                {/*        />*/}
                {/*    </DemoContainer>*/}
                {/*</LocalizationProvider>*/}

                    <Notes
                        notes={notes}
                        setNotes={setNotes}/>
                {/*</Grid>*/}
                {/*<Grid style={{paddingRight: "5px"}} xs={6}>*/}

                {/*</Grid>*/}
                {/*<Grid style={{paddingLeft: "5px"}} xs={6}>*/}
                    <SubmitButton
                        selectedTimeSpent={selectedTimeSpent}
                        selectedCustomer={selectedCustomer}
                        selectedJob={selectedJob}
                        selectedJobId={selectedJobId}
                        selectedLineManager={selectedLineManager}
                        updateSelectedJobId={updateSelectedJobId}
                        selectedAction={selectedAction}
                        notes={notes}
                        onReset={resetDropdowns}/>
                {/*</Grid>*/}
            {/*</Grid>*/}
            </Container>
        </div>
    );
}


export default Home;
