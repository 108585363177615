import { PublicClientApplication } from '@azure/msal-browser';
const redirectUri = window.location.origin;
export const msalConfig = {
    auth: {
        clientId: '5f0f0796-9640-4f32-85f0-c3e3397d63f5',
        authority: 'https://login.microsoftonline.com/862e772c-8d57-4711-a787-673b936eb664',
        redirectUri: redirectUri
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);
