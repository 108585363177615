import { getAccessToken, SCOPES } from '../hooks/useGraphApi';
import { jwtDecode } from 'jwt-decode';

const decodeToken = (token) => {
    try {
        const decoded = jwtDecode(token);
    console.log('Decoded:', decoded);
        return decoded;
    } catch (error) {
        console.error('Failed to decode token:', error);
        throw error;
    }
};

const getUserDetails = async (instance, accounts) => {
    let token = localStorage.getItem('accessToken');

    if (!token) {
        token = await getAccessToken(instance, accounts, SCOPES.filesReadWrite);
        localStorage.setItem('accessToken', token);
    }

    if (token) {
        const userDetails = decodeToken(token);
        return userDetails;
    }

    throw new Error('Unable to get user details');
};

export default getUserDetails;
