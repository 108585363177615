import React from 'react';
import {AppBar, IconButton, Toolbar, Tooltip, Typography} from '@mui/material';
import SignIn from "../Auth/SignIn";
import SignOut from "../Auth/SignOut";
import {useAccount, useMsal} from "@azure/msal-react";
import logo from '../../assets/images/logos/AERIAL_WHITE_m.png';
import classes from './Navbar.module.css';
import {Link, useLocation} from "react-router-dom";
import Button from "@mui/material/Button";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import Box from "@mui/material/Box";

const Navbar = () => {
    const {accounts} = useMsal();
    const account = useAccount(accounts[0]);
    const location = useLocation();

    return (
        <AppBar style={{height: "90px"}} position="static">
            <Toolbar style={{ justifyContent: 'center' }}>
                <a href="https://staff.aegraphics.tv/index.php">
                    <Box sx={{mt: "10px"}}>
                        <Typography variant="h6" style={{flexGrow: 1}}>
                            <img src={logo} alt="logo" className={classes.logo}/>
                        </Typography>
                    </Box>
                </a>
                {account && (location.pathname === '/submissions' || location.pathname === '/') && (
                    <Link to="/">
                        <Tooltip sx={{mt: "20px", ml: "20px"}} className="tertiary-button" title="Arial Time Tracking">
                            <Button variant="contained">
                                Time Tracking
                            </Button>
                        </Tooltip>
                    </Link>
                )}
                <div style={{flexGrow: 1}}/>
                {account && location.pathname === '/' && (
                    <Link to="/submissions">
                        <div style={{marginTop: "10px"}}>
                            <Button sx={{color: 'white'}}>
                                Time Submissions
                            </Button>
                        </div>
                    </Link>
                )}
                <div style={{marginTop: "10px"}} color="inherit">
                    {account ? <SignOut/> : <SignIn/>}
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
