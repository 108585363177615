import {InteractionRequiredAuthError} from "@azure/msal-browser";

export const SCOPES = {
    filesReadWrite: ["Files.ReadWrite"]
};

export async function getAccessToken(instance, accounts, scopes) {
        const silentRequest = {
            scopes: scopes,
            account: accounts[0]
        };
    try {
        const silentResult = await instance.acquireTokenSilent(silentRequest);
        if (silentResult.accessToken) {
            localStorage.setItem("accessToken", silentResult.accessToken);
        }
        return silentResult.accessToken;
    } catch (error) {
        // If a silent request fails, it may be necessary to acquire the token interactively
        if (error instanceof InteractionRequiredAuthError) {
            const interactiveResult = await instance.acquireTokenPopup(silentRequest);
            return interactiveResult.accessToken;
        } else {
            throw error;
        }
    }
}

