import React, {useState, useEffect} from 'react';
import {deleteEntry, fetchEntries, fetchTimeEntriesCsv, updateEntry} from '../services/api';
import getUserDetails from "../services/getUserDetails";
import {DataGrid,} from '@mui/x-data-grid';
import {
    ButtonGroup,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    MenuItem,
    Select
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from "@mui/material/Button";
import ActiveLastBreadcrumb from "../components/BredCrumbs/BreadCrumbs";
import {saveAs} from 'file-saver';

const TimeSubmission = ({selectedJobId, instance, accounts}) => {
    const [timeEntries, setTimeEntries] = useState([]);
    const [openSelects, setOpenSelects] = useState({});
    const [selection, setSelection] = useState([]);
    const [isButtonApprovalDisabled, setIsButtonApprovalDisabled] = useState(true);
    const [isMyEntriesButtonClicked, setIsMyEntriesButtonClicked] = useState(false);
    const [isLineManager, setIsLineManager] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [filterModel, setFilterModel] = useState({
        items: [
            { columnField: 'startDate', operatorValue: 'after', value: '2022-01-01T00:00' },
        ],
    });

    const accessToken = localStorage.getItem('accessToken');


    const handleApprovalChange = async (params) => {
        const {id, value: newApprovalStatus} = params;
        console.log(id, 'params id')
        const isApproved = newApprovalStatus === "Approved";
        const timeEntryIndex = timeEntries.findIndex(entry => entry.id === id);
        await handelApproval(timeEntryIndex, isApproved, newApprovalStatus, id);

    };
    const handelApproval = async (timeEntryIndex, isApproved, newApprovalStatus, id) => {
        if (timeEntryIndex !== -1) {
            const updatedTimeEntry = {
                ...timeEntries[timeEntryIndex],
                approvalStatus: isApproved,
                approvalStatusString: newApprovalStatus
            };

            try {
                const accessToken = localStorage.getItem('accessToken');
                await updateEntry(accessToken, updatedTimeEntry, id);
                setTimeEntries(prevEntries => [
                    ...prevEntries.slice(0, timeEntryIndex),
                    updatedTimeEntry,
                    ...prevEntries.slice(timeEntryIndex + 1)
                ]);
            } catch (error) {
                console.error('Failed to update approval status:', error);
            }
        } else {
            console.error('Failed to find the time entry:', id);
        }
    }

    const handleExport = async () => {
        try {
            const userDetails = await getUserDetails(instance, accounts);
            const filter = isMyEntriesButtonClicked ? `User&User=${userDetails.unique_name}` : `LineManager&LineManagerName=${userDetails.unique_name}`;
            const csvData = await fetchTimeEntriesCsv(accessToken, filter);
            console.log(csvData, 'csvData');
            const blob = new Blob([csvData], {type: 'text/csv;charset=utf-8'});
            saveAs(blob, 'time_entries.csv');
        } catch (error) {
            console.error('Failed to export time entries:', error);
        }
    };

    const getTimeEntries = async (entryType) => {
        try {
            const userDetails = await getUserDetails(instance, accounts);
            const endDate = new Date().toISOString();
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - 30);

            // let filter_Time_Entries = 'Filter={0}&User={1}&startDate={2}&endDate={3}';
            // filter_Time_Entries = filter_Time_Entries.replace('{0}', entryType).replace('{1}', userDetails.unique_name).replace('{2}', startDate.toISOString()).replace('{3}', endDate);
            let response = await fetchEntries(accessToken);
            let entries = response[entryType].map(entry => ({
                ...entry,
                name: `${entry.user.firstName} ${entry.user.lastName}`,
                approvalStatusString: entry.approvalStatus === true ? "Approved" : (entry.approvalStatus === false ? "Not-Approved" : "Pending"),
            }));
            setTimeEntries(entries);
            response["asLineManager"].length === 0 ? setIsLineManager(false) : setIsLineManager(true);
        } catch (error) {
            console.error(`Failed to fetch ${entryType} entries:`, error);
        }
    };

    useEffect(() => {
        setIsMyEntriesButtonClicked(true);
        getTimeEntries('asUser');
    }, []);

    const handleDeleteClick = (id) => {
        setDeleteId(id);
        setOpenDeleteDialog(true);
    };

    useEffect(() => {
        getTimeEntries();
    }, []);

    const confirmDelete = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            await deleteEntry(accessToken, deleteId);
            setTimeEntries(prevEntries => prevEntries.filter(entry => entry.id !== deleteId));
        } catch (error) {
            console.error('Failed to delete time entry:', error);
        }
        setOpenDeleteDialog(false);
    };


    const handleSelectedRows = async (newSelection) => {
        if (newSelection.length === 0) {
            setIsButtonApprovalDisabled(true);
            return;
        }
        setSelection(newSelection);
        setIsButtonApprovalDisabled(false);
        console.log('New selection:', newSelection);
    }

    const handleMultiApproval = async (isApproved, newApprovalStatus) => {
        if (selection.length === 0) {
            return;
        }
        for (let i = 0; i < selection.length; i++) {
            const timeEntryIndex = timeEntries.findIndex(entry => entry.id === selection[i]);
            const id = selection[i];
            await handelApproval(timeEntryIndex, isApproved, newApprovalStatus, id);
            console.log(selection[i], 'selection[i]');
            console.log(timeEntryIndex, 'timeEntryIndex');


        }
    }

    const sortedTimeEntries = [...timeEntries].sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    const columns = [
        {
            field: 'startDate',
            headerName: 'Time Submitted',
            width: 200,
            valueFormatter: (params) => new Date(params.value).toLocaleString()
        },
        {field: 'customer', headerName: 'Customer', width: 200},
        {field: 'actionType', headerName: 'Action Type', width: 200},
        {field: 'description', headerName: 'Description', width: 200},
        {field: 'notes', headerName: 'Notes', width: 200},
        ...(!isMyEntriesButtonClicked ? [{field: 'name', headerName: 'Name', width: 200}] : []),
        {field: 'timeSpent', headerName: 'Time Spent', width: 100},
        {
            field: 'approvalStatusString',
            headerName: 'Approval Status',
            width: 200,
            renderCell: (params) => (
                <Select
                    style={{width: "100%"}}
                    value={params.value}
                    onChange={(e) => handleApprovalChange({...params, value: e.target.value})}
                    open={openSelects[params.id]}
                    onOpen={() => setOpenSelects((prev) => ({...prev, [params.id]: true}))}
                    onClose={() => setOpenSelects((prev) => ({...prev, [params.id]: false}))}
                    IconComponent={KeyboardArrowDownIcon}
                    renderValue={(value) => `${value === "Approved" ? "Approved" : (value === "Not-Approved" ? "Not Approved" : "⚠️ Pending")}`}
                >
                    <MenuItem value="Pending" disabled>Pending</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Not-Approved">Not Approved</MenuItem>
                </Select>
            ),

        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => handleDeleteClick(params.row.id)}>
                        <DeleteIcon/>
                    </IconButton>
                </>
            ),
        },
    ];


    return (
        <div style={{margin: "0px 20px 20px 20px"}}>
            <ActiveLastBreadcrumb/>
            <ButtonGroup sx={{mt: 2, mb: 2}} disabled={isButtonApprovalDisabled} variant="outlined"
                         aria-label="Basic button group">
                <Button onClick={() => handleMultiApproval(true, 'Approved')}>Approved</Button>
                <Button onClick={() => handleMultiApproval(false, 'Not-Approved')}>Not Approved</Button>
            </ButtonGroup>
            &nbsp;
            &nbsp;
            <ButtonGroup className={isLineManager ? '' : 'hidden'} sx={{m: 2}} variant="outlined" color="inherit"
                         aria-label="Basic button group">
                <Button
                    style={isMyEntriesButtonClicked ? {
                        backgroundColor: 'var(--melon-red)',
                        color: 'var(--white)'
                    } : {color: 'var(--melon-red)'}}
                    variant={isMyEntriesButtonClicked ? "contained" : "outlined"}
                    onClick={() => {
                        setIsMyEntriesButtonClicked(true);
                        getTimeEntries('asUser');
                    }}
                >
                    My Entries
                </Button>
                <Button
                    style={!isMyEntriesButtonClicked ? {
                        backgroundColor: 'var(--melon-red)',
                        color: 'var(--white)'
                    } : {color: 'var(--melon-red)'}}
                    variant={!isMyEntriesButtonClicked ? "contained" : "outlined"}
                    onClick={() => {
                        setIsMyEntriesButtonClicked(false);
                        getTimeEntries('asLineManager');
                    }}
                >
                    Line Manager
                </Button>
            </ButtonGroup>
            <Button className="secondary-button"
                    variant="contained"
                    onClick={handleExport}
                    style={{float: 'right', marginTop: 10}}
            >
                Export
            </Button>
            <div style={{height: 800, width: '100%',}}>
                <DataGrid
                    rows={sortedTimeEntries}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    onRowSelectionModelChange={handleSelectedRows}
                    onEditCellChange={handleApprovalChange}
                    filterModel={filterModel}
                />
            </div>
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this entry?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default TimeSubmission;
