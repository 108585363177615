import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link} from 'react-router-dom';
import {useLocation} from "react-router-dom";


export default function ActiveLastBreadcrumb() {
    const location = useLocation();

    return (
        <div role="presentation" className="mt-10">
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover"
                      to="/"
                      style={{ color: 'white' }}
                      color={location.pathname === '/' ? 'text.primary' : 'inherit'}
                >
                    Areial Time Tracking
                </Link>
                <Link
                    underline="hover"
                    to="/submissions"
                    style={{ color: 'white' }}
                    color={location.pathname === '/submissions' ? 'text.primary' : 'inherit'}
                >
                    Time Submissions
                </Link>
            </Breadcrumbs>
        </div>
    );
}
