import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const TimeInput = ({ time, setTime, label }) => {
    const timeOptions = Array.from({ length: 17 }, (_, index) => {
        const hours = Math.floor((index * 30) / 60);
        const minutes = (index * 30) % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    });

    const handleTimeChange = (event) => {
        setTime(event.target.value);
    };

    return (
            <FormControl fullWidth margin="normal">
                <InputLabel>{label}</InputLabel>
                <Select
                    labelId="time-spent-label"
                    id="time-spent"
                    value={time}
                    label={label}
                    onChange={handleTimeChange}
                >
                    {timeOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
    );
};

export default TimeInput;
