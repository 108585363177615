import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { msalInstance } from './authentication/authConfig';
import { MsalProvider } from '@azure/msal-react';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>

        </MsalProvider>
    </React.StrictMode>
);

reportWebVitals();
