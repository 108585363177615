import React from 'react';
import {useMsal} from '@azure/msal-react';
import {getAccessToken, SCOPES} from "../../hooks/useGraphApi";
import Button from "@mui/material/Button";
import {LinearProgress, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import Alert from '@mui/material/Alert';
import {submitTimeEntry} from "../../services/api";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";


const SubmitButton = ({selectedTimeSpent, selectedCustomer, selectedJob, selectedAction, selectedJobId, notes, onReset, updateSelectedJobId, selectedLineManager}) => {
    const vertical = 'bottom';
    const horizontal = 'center';
    const {instance, accounts} = useMsal();
    const [submitting, setSubmitting] = React.useState(false);
    const [submitSuccess, setSubmitSuccess] = React.useState(false);
    const [submitError, setSubmitError] = React.useState(false);
    const [formValid, setFormValid] = React.useState(true);
    const [loading, setLoading] = React.useState(false);


    const handleSubmit = async () => {
        if (!selectedTimeSpent || !selectedCustomer || !selectedJob || !selectedJobId || !selectedAction || !selectedLineManager) {
            setFormValid(false);
            return;
        }
        setSubmitting(true);
        try {
            setLoading(true);
            const accessToken = await getAccessToken(instance, accounts, SCOPES.filesReadWrite);

            const updateData = {
                jobNo: selectedJobId ,
                description: selectedJob,
                customer: selectedCustomer.customer_name,
                notes: notes,
                timeSpent: selectedTimeSpent,
                actionType: selectedAction,
                lineManager: {
                    firstName: selectedLineManager.split('@')[0].split('.')[0],
                    lastName: selectedLineManager.split('@')[0].split('.')[1],
                    userName: selectedLineManager,
                    email: selectedLineManager
                },
                // approvedBy: "Some Approver",
                startDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
            };

            console.log("Submitting Data:", JSON.stringify(updateData, null, 2));

            const result = await submitTimeEntry(accessToken, updateData);
            onReset();
            setSubmitSuccess(true);
            updateSelectedJobId(selectedJobId);
            setLoading(false);
            setTimeout(() => {
                setSubmitSuccess(false);
                setLoading(false);
            }, 3000);
        } catch (error) {
            console.error("Submission Error:", error);
            setSubmitSuccess(false);
            setSubmitError(true);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div style={{display: 'block'}}>
            <LoadingButton style={{float: 'right'}}
                className="button"
                onClick={handleSubmit}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon/>}
                variant="contained"
            >
                <span>Submit</span>
            </LoadingButton>
            {submitSuccess && (
                <Snackbar
                    anchorOrigin={{vertical, horizontal}}
                    open={submitSuccess}
                    onClose={() => !setSubmitSuccess}
                    message="Successfully submitted the data!"
                    autoHideDuration={5000}
                    key={vertical + horizontal}
                >
                    <Alert severity="success">
                        Successfully submitted the data!
                    </Alert>
                </Snackbar>
            )}
            {submitError && (
                <Snackbar
                    anchorOrigin={{vertical, horizontal}}
                    open={submitError}
                    onClose={() => setSubmitError(false)}
                    autoHideDuration={5000}
                    message="Data was not submitted! Please try again."
                    key={vertical + horizontal}
                >
                    <Alert severity="error">
                        Data was not submitted! Please try again.
                    </Alert>
                </Snackbar>
            )}
            {!formValid && (
                <Snackbar
                    anchorOrigin={{vertical, horizontal}}
                    open={!formValid}
                    onClose={() => setFormValid(true)}
                    autoHideDuration={5000}
                    message="Please select options from the dropdowns."
                    key={vertical + horizontal}
                >
                    <Alert severity="warning">
                        Please select options from the dropdowns.
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
};

export default SubmitButton;
