import axios from 'axios';
import reactManifest from "react-manifest"

const BASE_URL = 'https://staff.aegraphics.tv/work-schedules/xydata/';
const AES_BASE_URL = 'https://time-trackerael.azurewebsites.net/';

export const fetchCustomers = async () => {
    try {
        const response = await axios.get(`${BASE_URL}customers.php`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch customers:', error);
        throw error;
    }
};

export const fetchJobs = async (customerName) => {
    try {
        const encodedCustomerName = encodeURIComponent(customerName);
        const url = `${BASE_URL}jobs.php?custname=${encodedCustomerName}`;
        const response = await axios.get(url, {
            headers: {
                'Accept': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch jobs for customer', error);
        throw error;
    }
};

export const fetchTimeEntriesCsv = async (accessToken, filter) => {
    try {
        const response = await axios.get(`${AES_BASE_URL}api/timeTracker/GetCsv?Filter=${filter}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch time entries', error);
        throw error;
    }
};

export const fetchEntries = async (accessToken) => {
    try {
        const response = await axios.get(`${AES_BASE_URL}api/timeTracker/GetUserLineManager`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            }
        });
        console.log('API response:', response.data)
        return response.data;
    } catch (error) {
        console.error('Failed to fetch time entries', error);
        throw error;
    }

}

export const fetchLineManagers = async (accessToken) => {
    try {
        const response = await axios.get(`${AES_BASE_URL}api/timeTracker/GetManagers`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch line managers:', error);
        throw error;
    }
}

export const submitTimeEntry = async (accessToken, timeEntry) => {
    try {
        const response = await axios.post(`${AES_BASE_URL}api/timeTracker`, timeEntry, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                persistChanges: true
            })
        });
        return response.data;
    } catch (error) {
        console.error('Failed to submit time entry', error);
        throw error;
    }
};

export const updateEntry = async (accessToken, timeEntry, id) => {
    try {
        const response = await axios.put(`${AES_BASE_URL}api/timeTracker/${id}`, timeEntry, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                persistChanges: true
            })
        });
        return response.data;
    } catch (error) {
        console.error('Failed to update time entry', error);
        throw error;
    }
};

export const deleteEntry = async (accessToken, id) => {
    try {
        const response = await axios.delete(`${AES_BASE_URL}api/timeTracker/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to delete time entry', error);
        throw error;
    }
};
