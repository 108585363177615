import React, {useEffect} from 'react';
import {AuthenticatedTemplate, useMsal} from '@azure/msal-react';
import Navbar from "./components/Navbar/Navbar";
import {Route, Routes} from "react-router-dom";
import {createTheme, CssBaseline} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import TimeSubmission from "./pages/TimeSubmission";
import Home from "./pages/Home";
import './index.css';
import reactManifest from 'react-manifest';


function App() {
    console.log(`App is running in ${process.env.NODE_ENV} mode.`);

    const { instance, accounts } = useMsal();

    useEffect(() => {

        // const manifestDetails = {
        //     "name": "My Web App",
        //     "short_name": "My App",
        //     "start_url": "index.html",
        //     "display": "standalone",
        //     "orientation": "portrait",
        //     "theme_color": "#000000",
        //     "background_color": "#ffffff",
        //     "icons": [
        //         {
        //             "src": "icon-192x192.png",
        //             "sizes": "192x192"
        //         },
        //         {
        //             "src": "icon-512x512.png",
        //             "sizes": "512x512"
        //         }
        //     ],


        // };

        // reactManifest.update(manifestDetails, "#manifest-placeholder");

    }, []);


    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Navbar/>
                <AuthenticatedTemplate>
                    <Routes>
                        <Route path="/submissions" element={<TimeSubmission instance={instance} accounts={accounts} />}/>
                        <Route path="/" element={<Home/>}/>
                    </Routes>
                </AuthenticatedTemplate>
            </ThemeProvider>
        </>
    );
}

export default App;
