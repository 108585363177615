import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';

const SignOut = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutPopup().catch(e => console.error(e));
    };

    return <Button color="inherit" onClick={handleLogout}>Sign Out</Button>;
};

export default SignOut;
