import {FormControl, InputLabel, Select, MenuItem, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect} from "react";


const Dropdown = ({label, options, selected, onChange, loading = false, valueKey}) => {

    if (label === 'Customers') {
        return (
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    id='customers'
                    options={options}
                    getOptionLabel={(option) => valueKey ? option[valueKey] : option}
                    style={{width: '100%'}}
                    renderInput={(params) => <TextField {...params} label={label} variant='outlined'/>}
                    onChange={(e, newValue) => onChange(newValue)}
                    value={selected || null}
                />
            </FormControl>
        );
    }
    if (label === 'line Manager') {
        return (
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    id='lineManager'
                    options={options}
                    getOptionLabel={(option) => valueKey ? option[valueKey] : option}
                    style={{width: '100%'}}
                    renderInput={(params) => <TextField {...params} label={label} variant='outlined'/>}
                    onChange={(e, newValue) => onChange(newValue)}
                    value={selected || null}
                />
            </FormControl>
        );
    }
    return (
        <FormControl fullWidth margin="normal">
            <InputLabel>{label}</InputLabel>
            <Select value={typeof selected === 'object' ? selected[valueKey] : selected}
                    onChange={(e) => onChange(e.target.value)}
                    label={label}>
                {loading ? (
                    <MenuItem value="">Loading...</MenuItem>
                ) : (
                    options ? options.map((option, index) => (
                        <MenuItem key={index} value={typeof option === 'object' ? option[valueKey] : option}>
                            {valueKey ? option[valueKey] : (typeof option === 'object' ? option.label : option)}
                        </MenuItem>
                    )) : <MenuItem value="">No options available</MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default Dropdown;
