import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';

const SignIn = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup().catch(e => console.error(e));
    };

    return <Button color="inherit" onClick={handleLogin}>LogIn</Button>;
};

export default SignIn;
