import {TextField} from "@mui/material";
import Box from "@mui/material/Box";

const Notes = ({notes, setNotes}) => {
    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    }
    return (
        <Box
            sx={{
                margin: '16px 0 8px 0',
                width: '100%',
                maxWidth: '100%',
            }}
        >
            <TextField fullWidth
                       label="Additional Notes"
                       id="Notes"
                       value={notes}
                       onChange={handleNotesChange}
            />
        </Box>
    );
}


export default Notes;