import { useState, useEffect } from 'react';

const useFetch = (fetchFunction, params = {}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [attempted, setAttempted] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (!attempted) {
                setLoading(true);
                setAttempted(true);
                try {
                    const response = await fetchFunction(params);
                    setData(response);
                } catch (error) {
                    setError(error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [fetchFunction, params, attempted]);
    return { data, loading, error };
};
export default useFetch;
